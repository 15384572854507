import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
    Collapse,
    Dialog,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CollectionTitle from '../asset/CollectionTitle'
import Link from '../common/util/input/Link'
import get, { getAtomicTemplate } from '../helpers/Api'
import {
    formatNumber,
    formatWAX,
    parseAtomicImmutableData,
} from '../helpers/FormatLinks'
import { DropPaper } from './DropPaper'

export const DropTemplateDetailDialog = ({ drop, asset, setAsset }) => {
    const [collapsedImmutableData, setCollapsedImmutableData] = useState(false)
    const [collapsedMarketInfo, setCollapsedMarketInfo] = useState(false)

    const [atomicData, setAtomicData] = useState(null)
    const [assetData, setAssetData] = useState(null)

    const [priceInfo, setPriceInfo] = useState('Loading...')

    const { author, collectionName, verified } = drop

    const minted = atomicData ? atomicData['issued_supply'] : ''
    const supply = atomicData ? atomicData['max_supply'] : ''

    const name =
        assetData && assetData['name']
            ? assetData['name']
            : drop && drop['namePattern']
            ? drop && drop['namePattern'].replace('{num}', '')
            : ''

    const { t } = useTranslation('common')

    useEffect(() => {
        if (asset !== null && asset.templateId) {
            getAtomicTemplate(asset.templateId, author).then((res) => {
                res && res.success && setAtomicData(res.data)
            })
            get(`price-info-template/${asset.templateId}`).then(setPriceInfo)
        }
    }, [asset])

    useEffect(() => {
        if (atomicData !== null) {
            const res = atomicData
                ? parseAtomicImmutableData(atomicData)
                : asset.idata
                ? JSON.parse(idata)
                : null
            setAssetData(res)
        }
    }, [atomicData])

    const searchIcon = (
        <img className={cn('w-5 h-5 flex ml-1 my-0')} src="/search.svg" />
    )
    const searchItemClassName = cn('flex justify-end cursor-pointer')

    const close = () => {
        setAsset(null)
    }

    return (
        <Dialog
            open={asset !== null}
            onClose={() => close()}
            maxWidth="md"
            fullWidth
        >
            <DropPaper title={name} noMargin>
                <div className="flex">
                    <CollectionTitle
                        author={author}
                        collectionName={collectionName}
                        collectionImage={asset?.collectionImage}
                        collectionThumbnail={
                            asset?.collectionThumbnail
                                ? asset?.collectionThumbnail
                                : 'https://ipfs.hivebp.io/media/author/' +
                                  author
                        }
                        verified={verified}
                    />
                </div>

                <Table size="small" sx={{ width: '100%' }}>
                    <TableBody>
                        {asset?.templateId && (
                            <TableRow>
                                <TableCell>Template ID:</TableCell>
                                <TableCell align="right">
                                    <Link
                                        className="text-primary hover:font-bold"
                                        href={`/template/${asset?.templateId}`}
                                    >
                                        {asset?.templateId}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>{t('asset.name')}:</TableCell>
                            <TableCell align="right">{name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('asset.minted')}:</TableCell>
                            <TableCell align="right">
                                {minted}
                                {parseInt(supply) > 0 ? ` / ${supply}` : ''}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('asset.schema')}:</TableCell>
                            <TableCell align="right">
                                <Link
                                    className="text-primary hover:font-bold"
                                    href={`/collection/${author}/${asset?.schema}`}
                                >
                                    {asset?.schema}
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <div
                                    className="flex cursor-pointer"
                                    onClick={() => {
                                        setCollapsedImmutableData(
                                            !collapsedImmutableData,
                                        )
                                    }}
                                >
                                    {collapsedImmutableData ? (
                                        <ArrowDropDownIcon />
                                    ) : (
                                        <ArrowDropUpIcon />
                                    )}
                                    <div className="text-primary font-bold my-auto">
                                        {!collapsedImmutableData
                                            ? 'Show More '
                                            : 'Hide '}
                                        Details (Immutable Data)
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Collapse in={collapsedImmutableData}>
                    <Table size="small">
                        <TableBody>
                            {assetData ? (
                                Object.keys(assetData).map(
                                    (key, i) =>
                                        ![
                                            'img',
                                            'video',
                                            'backimg',
                                            'pfp',
                                            '3dimg',
                                            'model',
                                            'img1',
                                            'img2',
                                            'img3',
                                            'img4',
                                        ].includes(key) && (
                                            <TableRow>
                                                <TableCell>{key}:</TableCell>
                                                <TableCell align="right">
                                                    {'' + assetData[key]}
                                                </TableCell>
                                            </TableRow>
                                        ),
                                )
                            ) : (
                                <LinearProgress />
                            )}
                        </TableBody>
                    </Table>
                </Collapse>

                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <div
                                    className="flex cursor-pointer"
                                    onClick={() => {
                                        setCollapsedMarketInfo(
                                            !collapsedMarketInfo,
                                        )
                                    }}
                                >
                                    {collapsedMarketInfo ? (
                                        <ArrowDropDownIcon />
                                    ) : (
                                        <ArrowDropUpIcon />
                                    )}
                                    <div className="text-primary font-bold my-auto">
                                        {!collapsedMarketInfo
                                            ? 'Show '
                                            : 'Hide '}
                                        Market Info
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Collapse in={collapsedMarketInfo}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {t('asset.average_bought')}:
                                </TableCell>
                                <TableCell align="right">
                                    {priceInfo && priceInfo['isLoading']
                                        ? 'Loading...'
                                        : priceInfo['average']
                                        ? `${formatWAX(priceInfo['average'])}`
                                        : null}
                                    {priceInfo &&
                                    priceInfo['isLoading'] ? null : priceInfo[
                                          'average_usd'
                                      ] ? (
                                        <span className="text-sm">
                                            /$
                                            {formatNumber(
                                                priceInfo['average_usd'],
                                            )}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('asset.num_sales')}:</TableCell>
                                <TableCell align="right">
                                    {priceInfo && priceInfo['isLoading']
                                        ? 'Loading...'
                                        : priceInfo['num_sales']
                                        ? `${priceInfo['num_sales']}`
                                        : '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {t('asset.lowest_available_price')}:
                                </TableCell>
                                <TableCell align="right">
                                    <Link
                                        href={`/market/?term=${escape(
                                            asset?.templateId,
                                        )}&collection=${author}&schema=${
                                            asset?.schema
                                        }&order_by=offer_asc&limit=10`}
                                    >
                                        <div className={searchItemClassName}>
                                            {priceInfo['isLoading']
                                                ? 'Loading...'
                                                : priceInfo['lowest']
                                                ? `${formatWAX(
                                                      priceInfo['lowest'],
                                                  )}`
                                                : null}
                                            {priceInfo['isLoading']
                                                ? null
                                                : priceInfo['lowest_usd']
                                                ? `/ $${formatNumber(
                                                      priceInfo['lowest_usd'],
                                                  )}`
                                                : '-'}
                                            {searchIcon}
                                        </div>
                                    </Link>
                                </TableCell>
                            </TableRow>
                            {priceInfo && priceInfo['last_sold'] && (
                                <TableRow>
                                    <TableCell>
                                        {t('asset.last_sold')}:
                                    </TableCell>
                                    <TableCell align="right">
                                        <Link
                                            href={`/market/?term=${escape(
                                                asset?.templateId,
                                            )}&collection=${author}&schema=${
                                                asset?.schema
                                            }&order_by=date_desc&limit=100`}
                                        >
                                            <div
                                                className={searchItemClassName}
                                            >
                                                {formatNumber(
                                                    priceInfo['last_sold'],
                                                )}{' '}
                                                WAX / $
                                                {formatNumber(
                                                    priceInfo['last_sold_usd'],
                                                )}
                                                {searchIcon}
                                            </div>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Collapse>
            </DropPaper>
        </Dialog>
    )
}
