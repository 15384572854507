import {
    Alert,
    AlertTitle,
    Collapse,
    LinearProgress,
    TextField,
} from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useLanguage } from '../../hooks'

import { getDropsAccountStats, getIsOnWhitelist } from '../helpers/Api'
import {
    getValidation,
    MissingDropOwnershipTemplates,
} from '../helpers/DropProofs'
import { RenderingWarning } from '../renderingwarning/RenderingWarning'
import { Context, useSharedState } from '../waxplorer/Waxplorer'
import { DropAlert } from './DropAlert'
import { DropButton } from './DropButton'
import { AddToCalendarButton } from 'add-to-calendar-button-react'

export const DropButtons = (props) => {
    const dropData = props['dropData']
    const drop = props['drop']
    const isPremintDrop = props['isPremintDrop']
    const isPremintPoolReady = props['isPremintPoolReady']
    const isPFP = Object.keys(drop).includes('pfpAttributes')
    const [error, setError] = useState()
    const [isClaiming, setIsClaiming] = useState()
    const [bought, setBought] = useState(false)

    const [state, , { login }] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const handleBought = (buyData) => {
        if (buyData) {
            setBought(buyData['bought'])
            if (buyData['error']) setError(buyData['error'])
        } else {
            setBought(false)
        }
        setIsClaiming(false)
    }

    const [dropInterval, setDropInterval] = useState(null)
    const [dropTimeLeft, setDropTimeLeft] = useState('')
    const [dropReady, setDropReady] = useState(false)
    const [dropEnded, setDropEnded] = useState(false)
    const soldOut =
        dropData.maxClaimable > 0 &&
        dropData.currentClaimed === dropData.maxClaimable
    const [claimAmount, setClaimAmount] = useState(1)
    // accountStats is important for user specific cooldown times
    const [accountStats, setAccountStats] = useState(null)
    const [unfreezeInterval, setUnfreezeInterval] = useState(null)
    const setResetView = props['setResetView']
    const resetView = props['resetView']
    const [unfreezeTime, setUnfreezeTime] = useState(null)

    const [whitelist, setWhitelist] = useState(null)
    const [missingProof, setMissingProof] = useState(null)
    // Required assets are needed only in the Popup, as it's passed into the contract
    const [, setRequiredAssets] = useState(null)
    const [isLoadingValidation, setIsLoadingValidation] = useState(true)
    const [isOnWhitelist, setIsOnWhitelist] = useState(false)

    const refresh = () => {
        setBought(false)
        setResetView(true)
    }

    const [, dispatch] = useContext(Context)

    const claimTime = accountStats ? accountStats.last_claim_time : 0
    const lastClaimed = claimTime ? moment().unix() - claimTime : 0

    const { t } = useLanguage('common')

    const accountLimit = dropData.accountLimit
    const accountLimitCooldown = dropData.accountLimitCooldown

    const claim = () => {
        if (claimAmount) {
            setIsClaiming(true)
            dispatch({ type: 'SET_DROP', payload: dropData })
            dispatch({ type: 'SET_CLAIM_AMOUNT', payload: claimAmount })
            dispatch({
                type: 'SET_CALLBACK',
                payload: (bought) => handleBought(bought),
            })
            dispatch({
                type: 'SET_ACTION',
                payload: isPFP ? 'buy_pfp' : 'buy_drop',
            })
        }
    }

    useEffect(() => {
        if (userName) {
            getDropsAccountStats(userName, dropData.dropId, state).then(
                setAccountStats,
            )
            getValidation({
                state: state,
                userName: userName,
                drop: dropData,
                setWhitelist: setWhitelist,
                setMissingProof: setMissingProof,
                setRequiredAssets: setRequiredAssets,
                setIsLoadingValidation: setIsLoadingValidation,
            })
        }
    }, [userName, resetView])

    useEffect(() => {
        if (userName && whitelist) {
            getIsOnWhitelist(dropData, userName, state).then(setIsOnWhitelist)
        }
    }, [whitelist])

    useEffect(() => {
        if (!dropReady) {
            const currentTime = moment()

            if (soldOut) {
                return
            }

            if (dropData.endTime && currentTime.unix() > dropData.endTime) {
                setDropEnded(true)
                return
            }

            const diffTime = dropData.startTime - currentTime.unix()

            if (diffTime > 0) {
                if (dropInterval) {
                    clearInterval(dropInterval)
                }

                const interval = 1000

                setDropInterval(
                    setInterval(function () {
                        const currentTime = moment()

                        const diffTime = dropData.startTime - currentTime.unix()

                        let duration = moment.duration(
                            diffTime * 1000,
                            'milliseconds',
                        )

                        if (duration.asSeconds() < 0) setDropTimeLeft(null)
                        else
                            setDropTimeLeft(
                                `${duration.months()}M ${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`,
                            )
                    }, interval),
                )
            } else {
                setDropReady(true)
            }
        }
    }, [dropReady])

    useEffect(() => {
        if (dropTimeLeft === null) {
            const currentTime = moment()
            if (soldOut) {
                return
            }
            if (dropData.endTime && currentTime.unix() > dropData.endTime) {
                setDropEnded(true)
                return
            }
            setDropReady(true)
        }
    }, [dropTimeLeft])

    useEffect(() => {
        const currentTime = moment()

        if (
            accountStats &&
            accountStats.counter &&
            accountStats.counter >= dropData.accountLimit &&
            dropData.accountLimitCooldown &&
            !soldOut &&
            dropReady
        ) {
            const a = currentTime.unix()
            const b = accountStats.last_claim_time + drop.accountLimitCooldown
            const diffTime = b - a

            if (diffTime > 0) {
                if (unfreezeInterval) {
                    clearInterval(unfreezeInterval)
                }

                let duration = moment.duration(diffTime * 1000, 'milliseconds')
                const interval = 1000

                setUnfreezeInterval(
                    setInterval(function () {
                        duration = moment.duration(
                            duration - interval,
                            'milliseconds',
                        )

                        if (dropInterval) {
                            clearInterval(dropInterval)
                        }

                        if (duration.asSeconds() < 0) setUnfreezeTime(null)
                        else
                            setUnfreezeTime(
                                `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`,
                            )
                    }, interval),
                )
            }
        }
    }, [claimTime])

    const free = dropData?.listingPrice === '0 NULL'
    let unPackUrl = ''

    if (dropData?.templatesToMint?.length) {
        try {
            const idata = JSON.parse(dropData.templatesToMint[0].idata)
            if (Object.keys(idata).includes('unpack_url')) {
                unPackUrl = idata['unpack_url'].replace(
                    'https://nfthive.io',
                    '',
                )
            }
        } catch (e) {
            console.error('Invalid Immutable Data')
        }
    }

    return (
        <div className="mb-5">
            <DropAlert condition={accountLimit > 0} severity="info">
                {accountLimitCooldown
                    ? t('drops.account_limit_with_cooldown_exp', {
                          limit: accountLimit,
                          cooldown: accountLimitCooldown,
                      })
                    : t('drops.account_limit_exp', { limit: accountLimit })}
            </DropAlert>

            <RenderingWarning
                open={isPFP}
                contract={'nfthivedrops'}
                id={drop['dropId']}
            />
            {accountStats && accountLimit ? (
                <DropAlert
                    condition={accountStats && accountLimit}
                    severity="info"
                >
                    {t('drops.remaining_claims')}:{' '}
                    {accountLimit && lastClaimed < dropData.accountLimitCooldown
                        ? accountLimit - accountStats.counter
                        : accountLimit}
                </DropAlert>
            ) : null}

            <DropAlert condition={unfreezeTime} severity="info">
                {t('drops.accountlimit_resets_in')}: {unfreezeTime}
            </DropAlert>

            {!dropEnded && !soldOut ? (
                <div className="relative">
                    {isClaiming ? (
                        <div className="my-5">
                            <LinearProgress />
                        </div>
                    ) : bought ? (
                        <div>
                            <DropAlert
                                condition={bought}
                                title="Success!"
                                severity="success"
                            >
                                ♥ {t('drops.thank_you_for_your_support')} ♥
                            </DropAlert>
                            <DropAlert condition={isPFP} severity="info">
                                {t('drops.pfp_explanation')}
                            </DropAlert>
                            <div className={'flex flex-wrap'}>
                                <DropButton
                                    className="w-full md:w-46 m-2"
                                    fullWidth
                                    onClick={() => refresh()}
                                >
                                    {t('drops.buy_more')}
                                </DropButton>
                                <DropButton
                                    className="w-full md:w-46 m-2"
                                    href={`/user/${userName}?tab=inventory#Tabs`}
                                    fullWidth
                                >
                                    {t('drops.view_in_inventory')}
                                </DropButton>
                                {unPackUrl ? (
                                    <DropButton
                                        className="w-full md:w-46 m-2"
                                        href={unPackUrl}
                                        external={unPackUrl.includes('http')}
                                        fullWidth
                                    >
                                        {t('drops.unpack_now')}
                                    </DropButton>
                                ) : (
                                    ''
                                )}
                                <DropButton
                                    className="w-full md:w-46 m-2"
                                    href="/dropcalendar"
                                    fullWidth
                                >
                                    {t('drops.drop_calendar')}
                                </DropButton>
                                <DropButton
                                    className="w-full md:w-46 m-2"
                                    href="/drops"
                                    fullWidth
                                >
                                    {t('drops.all_drops')}
                                </DropButton>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="mt-2">
                                <div>
                                    <div className="w-full md:w-48 mb-5">
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                type: 'number',
                                                min: 1,
                                                max: Math.min(
                                                    dropData.maxClaimable === 0
                                                        ? 50
                                                        : dropData.maxClaimable -
                                                              dropData.currentClaimed,
                                                    accountLimit === 0
                                                        ? 50
                                                        : accountLimit,
                                                ),
                                            }}
                                            label={
                                                free
                                                    ? t('drops.amount_to_claim')
                                                    : t(
                                                          'drops.amount_to_purchase',
                                                      )
                                            }
                                            onChange={(e) =>
                                                setClaimAmount(
                                                    parseInt(e.target.value),
                                                )
                                            }
                                            value={claimAmount}
                                            size="small"
                                            error={claimAmount > 50}
                                            disabled={
                                                !userName ||
                                                isLoadingValidation ||
                                                (whitelist && !isOnWhitelist) ||
                                                missingProof ||
                                                !dropReady ||
                                                (accountStats &&
                                                    accountLimit > 0 &&
                                                    accountLimit -
                                                        accountStats.counter <=
                                                        0 &&
                                                    (unfreezeTime ||
                                                        !dropData.accountLimitCooldown)) ||
                                                (isPremintDrop &&
                                                    isPremintPoolReady ===
                                                        false)
                                            }
                                            helperText={
                                                claimAmount <= 50
                                                    ? null
                                                    : 'Cannot claim more than 50 at a time'
                                            }
                                        />
                                    </div>
                                    <DropButton
                                        className="mb-5 w-full md:w-48"
                                        fullWidth
                                        size="large"
                                        variant="outlined"
                                        onClick={userName ? claim : login}
                                        disabled={
                                            claimAmount > 50 ||
                                            (userName &&
                                                (isLoadingValidation ||
                                                    (whitelist &&
                                                        !isOnWhitelist) ||
                                                    missingProof ||
                                                    !dropReady ||
                                                    (accountStats &&
                                                        accountLimit > 0 &&
                                                        accountLimit -
                                                            accountStats.counter <=
                                                            0 &&
                                                        (unfreezeTime ||
                                                            !dropData.accountLimitCooldown)) ||
                                                    (isPremintDrop &&
                                                        isPremintPoolReady ===
                                                            false)))
                                        }
                                    >
                                        {dropTimeLeft && !dropReady
                                            ? dropTimeLeft
                                            : userName
                                            ? free
                                                ? t('drops.claim')
                                                : t('drops.purchase')
                                            : t('navigation.login')}
                                    </DropButton>
                                    <DropAlert
                                        condition={!isClaiming && error}
                                        severity={'error'}
                                    >
                                        {error}
                                    </DropAlert>
                                    <Collapse
                                        in={
                                            userName &&
                                            dropData.authRequired &&
                                            isLoadingValidation
                                        }
                                    >
                                        <div className="border border-paperl rounded-md p-5 mb-5">
                                            <div className="italic">
                                                Checking if all requirements are
                                                fulfilled...
                                            </div>
                                            <div className="my-5">
                                                <LinearProgress />
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            {accountStats &&
                            accountLimit > 0 &&
                            accountLimit - accountStats.counter <= 0 &&
                            (unfreezeTime || !dropData.accountLimitCooldown) ? (
                                <Alert severity="error">
                                    {t('drops.already_claimed_maximum')}
                                </Alert>
                            ) : !dropReady ? (
                                <div>
                                    <Alert severity="error">
                                        {`${t('drops.drop_not_live')}: ${moment
                                            .unix(dropData.startTime)
                                            .toLocaleString()}`}
                                    </Alert>
                                    <div className={'my-4'}>
                                        <AddToCalendarButton
                                            name={
                                                dropData.name + ' NFTHive Drop'
                                            }
                                            startDate={moment
                                                .unix(dropData.startTime)
                                                .format('YYYY-MM-DD')}
                                            options={[
                                                'Apple',
                                                'Google',
                                                'iCal',
                                                'Microsoft365',
                                                'Outlook.com',
                                                'Yahoo',
                                            ]}
                                            location={props['dropLink']}
                                            timeZone={
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone
                                            }
                                            startTime={moment
                                                .unix(dropData.startTime)
                                                .format('HH:mm')}
                                            endTime={moment
                                                .unix(dropData.startTime)
                                                .format('HH:mm')}
                                            buttonStyle={'round'}
                                        />
                                    </div>
                                </div>
                            ) : whitelist && !isOnWhitelist ? (
                                <Alert severity="error">
                                    <AlertTitle>
                                        {t('drops.not_on_the_whitelist')}
                                    </AlertTitle>
                                    {t('drops.whitelist_explanation')}
                                </Alert>
                            ) : missingProof ? (
                                <MissingDropOwnershipTemplates
                                    t={t}
                                    missingProof={missingProof}
                                    closeCallBack={null}
                                />
                            ) : isPremintDrop &&
                              isPremintPoolReady === false ? (
                                <Alert severity="error">
                                    {t('drops.no_assets_added')}
                                </Alert>
                            ) : null}
                        </div>
                    )}
                </div>
            ) : null}

            <DropAlert condition={dropEnded} severity="warning">
                <div>Drop Ended</div>
            </DropAlert>
            <DropAlert condition={soldOut} severity="warning">
                Sold Out!
            </DropAlert>

            {(soldOut || dropEnded) &&
            dropData.collectionName &&
            dropData.templatesToMint &&
            dropData.templatesToMint[0] &&
            dropData.templatesToMint[0].schema ? (
                <DropButton
                    size="large"
                    className="mt-5"
                    href={`/market?collection=${dropData.collectionName}&schema=${dropData.templatesToMint[0].schema}&order_by=offer_asc&limit=10`}
                >
                    {t('drops.search_market_for_similar_nfts')}
                </DropButton>
            ) : null}
        </div>
    )
}
