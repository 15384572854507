import cn from 'classnames'
import React from 'react'
import Button from './Button'

export const Icon = ({ className, ...props }) => (
    <Button
        className={cn(
            'relative h-6.5',
            'text-lg text-white text-center no-underline',
            className,
        )}
        {...props}
    />
)

export default Icon
