import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import InfoIcon from '@mui/icons-material/Info'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
    Chip,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'

import Link from '../common/util/input/Link'
import {
    get,
    getDelphiMedian,
    getDropPrices,
    getDropReferrals,
    post,
} from '../helpers/Api'
import {
    copyTextToClipboard,
    formatNumber,
    formatPercentage,
} from '../helpers/FormatLinks'
import MarkDown from '../markdown/MarkDown'
import { PackContent } from '../packcontent/PackContent'
import SearchComponent from '../searchitems'
import { useSharedState } from '../waxplorer/Waxplorer'
import { DropAlert } from './DropAlert'
import { DropItemImage } from './DropItemImage'
import { DropPaper } from './DropPaper'
import { DropPfpAttributeDetailDialog } from './DropPfpAttributeDetailDialog'
import { DropPfpAttributesList } from './DropPfpAttributesList'
import { DropPrice } from './DropPrice'
import { DropTemplateDetailDialog } from './DropTemplateDetailDialog'
import { RandomPfpPreview } from './RandomPfpPreview'

export const DropComponent = ({ drop }) => {
    const [dropData, setDropData] = useState(null)
    const [resetView, setResetView] = useState(null)
    const [isPremintDrop, setIsPremintDrop] = useState(null)
    const [assetsInPool, setAssetsInPool] = useState(null)
    const [isPremintPoolReady, setIsPremintPoolReady] = useState(null)
    const [authorized, setAuthorized] = useState(null)
    const [dropPurchases, setDropPurchases] = useState(null)
    const [waxPrice, setWaxPrice] = useState(null)
    const [referralShare, setReferralShare] = useState(0)

    const [selectedPfpAttributeIndex, setSelectedPfpAttributeIndex] =
        useState(null)
    const [selectedAsset, setSelectedAsset] = useState(null)

    const { dropId, contract, author } = drop

    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const dropLink =
        process.env.NEXT_PUBLIC_TESTNET !== 'TRUE'
            ? 'https://nfthive.io/drop/' + dropId
            : 'https://test.nfthive.io/drop/' + dropId
    const listingPrice = dropData && dropData.listingPrice
    const releaseId = drop && drop.releaseId

    const [assetIdsToMints, setAssetIdsToMints] = useState(new Map())
    const [dropPrices, setDropPrices] = useState(null)

    const parseUSDListingPrice = (median, amount, usd) => {
        if (median) {
            setWaxPrice(
                formatNumber((amount * usd) / (median / 10000.0)) + ' WAX',
            )
        }
    }

    const { t } = useTranslation('common')

    useEffect(() => {
        if (listingPrice && listingPrice.includes(' USD')) {
            getDelphiMedian(state).then((res) =>
                parseUSDListingPrice(
                    res,
                    1,
                    parseFloat(listingPrice.replace(' USD', '')),
                ),
            )
        }
    }, [listingPrice])

    const getAuthorized = async (collectionName) => {
        const response = await fetch(
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
                `/atomicassets/v1/collections/${collectionName}`,
        )
        const json = await response.json()
        const auth =
            json.data && json.data.authorized_accounts
                ? json.data.authorized_accounts
                : []
        setAuthorized(auth)
    }

    const parseTemplatesToMint = async (assetData) => {
        const templates = []

        if (assetData && assetData.length > 0) {
            setIsPremintDrop(assetData[0].pool_id === 1)
        }

        assetData.map((asset) => {
            const templateId = asset.template_id
            templates.push(templateId)
        })

        return Promise.all(
            templates.map((templateId) =>
                get('template/' + templateId.toString()),
            ),
        )
    }

    const assets = dropData ? dropData.templatesToMint : []

    const parseDrop = async (drop, templates) => {
        for (const template of templates) {
            fetchAssetsData(template.author, template.templateId)
        }

        let displayData = ''
        try {
            displayData = drop.display_data ? JSON.parse(drop.display_data) : ''
        } catch (e) {
            displayData = drop.display_data
        }

        const result = {
            collectionName: drop.collection_name,
            dropId: drop.drop_id,
            accountLimit: drop.account_limit,
            accountLimitCooldown: drop.account_limit_cooldown,
            currentClaimed: drop.current_claimed,
            maxClaimable: drop.max_claimable,
            name: displayData.name,
            listingPrice: drop.listing_price,
            description: displayData.description,
            templatesToMint: templates,
            endTime: drop.end_time,
            startTime: drop.start_time,
            authRequired: drop.auth_required,
            attributes: drop.attributes ? drop.attributes : null,
        }

        setDropData(result)
        getAuthorized(drop.collection_name)
    }

    const getDropPurchases = async (dropId, contract) => {
        if (process.env.NEXT_PUBLIC_TESTNET === 'TRUE') return
        get(
            `drop-purchases?drop_id=${dropId}&contract=${contract}&limit=200`,
        ).then(setDropPurchases)
    }

    const getDrop = async (dropId, state) => {
        let dropInfoBody = null
        let poolInfoBody = null

        if (drop['pfpAttributes']) {
            dropInfoBody = {
                code: contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1,
                lower_bound: dropId,
                upper_bound: dropId,
                reverse: 'true',
                scope: contract,
                show_payer: 'false',
                table: 'pfps',
                table_key: '',
            }
        } else {
            dropInfoBody = {
                code: contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1,
                lower_bound: dropId,
                upper_bound: dropId,
                reverse: 'true',
                scope: contract,
                show_payer: 'false',
                table: 'drops',
                table_key: '',
            }

            poolInfoBody = {
                code: contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1,
                lower_bound: dropId,
                upper_bound: dropId,
                reverse: 'true',
                scope: drop.author,
                show_payer: 'false',
                table: 'droppools',
                table_key: '',
            }
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : state.api + '/v1/chain/get_table_rows'

        const dropInfoRes = await post(url, dropInfoBody)

        if (
            dropInfoRes &&
            dropInfoRes.status === 200 &&
            dropInfoRes.data &&
            dropInfoRes.data.rows
        ) {
            if (drop['pfpAttributes']) {
                dropInfoRes.data.rows.map(async (drop) => {
                    parseTemplatesToMint([
                        { template_id: drop.template_id },
                    ]).then((templates) => parseDrop(drop, templates))
                })
            } else {
                dropInfoRes.data.rows.map(async (drop) => {
                    if (drop.assets_to_mint)
                        parseTemplatesToMint(drop.assets_to_mint).then(
                            (templates) => parseDrop(drop, templates),
                        )
                })
            }
        }

        if (contract === 'nfthivedrops' && poolInfoBody) {
            const poolInfoRes = await post(url, poolInfoBody)

            if (
                poolInfoRes &&
                poolInfoRes.status === 200 &&
                poolInfoRes.data &&
                poolInfoRes.data.rows &&
                poolInfoRes.data.rows.length > 0
            ) {
                setIsPremintPoolReady(poolInfoRes.data.rows[0].pool_ready)
                setAssetsInPool(poolInfoRes.data.rows[0].assets)
            }
        }
    }

    const fetchAssetsData = async (author, templateId) => {
        const res = await fetch(
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
                `/atomicassets/v1/assets?collection_name=${author}&template_id=${templateId}`,
        )
        if (!res.status === 200) {
            return
        }
        const json = await res.json()
        const tempAssetIdsToMintsMap = new Map(assetIdsToMints)
        for (const asset of json.data) {
            if (asset.asset_id && asset.template_mint) {
                tempAssetIdsToMintsMap.set(asset.asset_id, asset.template_mint)
            }
        }
        setAssetIdsToMints(tempAssetIdsToMintsMap)
    }

    const parseDropPrices = (res) => {
        if (res && Object.keys(res).includes('listing_prices')) {
            setDropPrices(res['listing_prices'])
        }
    }

    const parseReferrals = (res) => {
        if (res && res['share']) {
            setReferralShare(res['share'])
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            getDrop(dropId, state)
            if (contract === 'nfthivedrops')
                getDropReferrals(dropId, state).then(parseReferrals)
            getDropPrices(dropId, state).then(parseDropPrices)
            getDropPurchases(dropId, contract)
        }
        if (resetView) setResetView(false)
    }, [dropId, resetView])

    const free =
        dropData &&
        dropData.listingPrice &&
        (dropData.listingPrice === '0 NULL' ||
            dropData.listingPrice === '0.00000000 WAX')

    return (
        <div className="w-full">
            {dropData ? (
                <DropPfpAttributeDetailDialog
                    attributes={dropData.attributes}
                    index={selectedPfpAttributeIndex}
                    setIndex={setSelectedPfpAttributeIndex}
                    collectionName={dropData.collectionName}
                    schema={dropData.templatesToMint[0].schema}
                />
            ) : null}
            {drop ? (
                <DropTemplateDetailDialog
                    drop={drop}
                    asset={selectedAsset}
                    setAsset={setSelectedAsset}
                />
            ) : null}
            <DropAlert
                condition={
                    dropData && authorized && authorized.includes(userName)
                }
                severity="info"
            >
                {t('drops.this_is_your_drop')}
            </DropAlert>
            {referralShare ? (
                <DropAlert condition={referralShare} severity="info">
                    {t('drops.you_could_earn_x_referral', {
                        x: formatPercentage(referralShare / 100.0),
                    })}
                </DropAlert>
            ) : null}
            <DropAlert
                condition={
                    isPremintDrop &&
                    !isPremintPoolReady &&
                    dropData?.currentClaimed === 0 &&
                    authorized &&
                    authorized.includes(userName)
                }
                severity="error"
                title="Action Required!"
            >
                This is a premint drop, and it has not been finished yet! People
                are <span className="underline">not</span> able to purchase your
                drop right now!
                <Link href={`/creator/${dropData?.collectionName}/dropcreator`}>
                    <div className="my-2 underline text-bold">
                        Click here to go to the creator and make the required
                        changes
                    </div>
                </Link>
                (This message is only visible to you)
            </DropAlert>
            {!dropData ? (
                <div className="flex justify-center mt-5">
                    <CircularProgress />
                </div>
            ) : null}
            <div className="flex flex-wrap mb-5 md:mb-10">
                <div className="w-full pt-8 pr-0 md:w-1/2 md:pr-4">
                    {/* Left Side */}
                    {dropData && assets?.length > 0
                        ? assets.map((asset, index) => {
                              return (
                                  <div
                                      className={cn(
                                          'w-full border dark:!bg-paper bg-zinc-200 border-zinc-200 dark:!border-paperl shadow-xl rounded-md',
                                      )}
                                  >
                                      <div className="p-2 md:h-[10vh] flex flex-wrap border-b border-zinc-200 dark:!border-paperl">
                                          <div className="w-0 md:w-1/10" />
                                          <div className="w-full my-auto text-2xl font-semibold text-center md:w-8/10">
                                              {asset.name}
                                          </div>
                                          <div className="flex w-full my-auto md:w-1/10 justify-evenly md:justify-end">
                                              <div className="my-auto">
                                                  <IconButton
                                                      onClick={() => {
                                                          copyTextToClipboard(
                                                              referralShare
                                                                  ? dropLink +
                                                                        '?referral=' +
                                                                        userName
                                                                  : dropLink,
                                                          )
                                                      }}
                                                  >
                                                      <Tooltip
                                                          title={
                                                              referralShare
                                                                  ? t(
                                                                        'drops.copy_referral',
                                                                    )
                                                                  : t(
                                                                        'drops.copy_drop_link',
                                                                    )
                                                          }
                                                      >
                                                          <ContentCopyIcon />
                                                      </Tooltip>
                                                  </IconButton>
                                              </div>
                                              <div className="my-auto">
                                                  <IconButton
                                                      onClick={() =>
                                                          setSelectedAsset(
                                                              asset,
                                                          )
                                                      }
                                                  >
                                                      <Tooltip title="Show Template Details">
                                                          <InfoIcon />
                                                      </Tooltip>
                                                  </IconButton>
                                              </div>
                                              <div className="my-auto">
                                                  <Link
                                                      href={`/template/${asset.templateId}`}
                                                      external
                                                  >
                                                      <IconButton size="small">
                                                          <Tooltip title="Go to Template">
                                                              <OpenInNewIcon />
                                                          </Tooltip>
                                                      </IconButton>
                                                  </Link>
                                              </div>
                                              <div className="my-auto">
                                                  {asset.image ? (
                                                      <Link
                                                          href={
                                                              asset.image.startsWith(
                                                                  'http',
                                                              )
                                                                  ? asset.image
                                                                  : config.ipfs +
                                                                    asset.image.replace(
                                                                        'video:',
                                                                        '',
                                                                    )
                                                          }
                                                          external
                                                      >
                                                          <IconButton size="small">
                                                              <Tooltip title="Show Full Image">
                                                                  <OpenInFullIcon />
                                                              </Tooltip>
                                                          </IconButton>
                                                      </Link>
                                                  ) : null}
                                              </div>
                                          </div>
                                      </div>
                                      <DropItemImage
                                          item={asset}
                                          drop={drop}
                                          dropData={dropData}
                                          key={'DropItemImage' + index}
                                      />
                                  </div>
                              )
                          })
                        : null}
                </div>
                <div className="w-full pt-8 pl-0 md:w-1/2 md:pl-4">
                    {/* Right Side */}
                    {dropData ? (
                        <div>
                            <DropPaper noMargin className="mb-5 md:mb-10">
                                <h1 className="mb-5 text-xl font-bold md:text-4xl md:font-semibold">
                                    {dropData.name
                                        ? dropData.name
                                        : drop.dropId}
                                </h1>
                                <div className="text-lg md:text-2xl md:font-semibold">
                                    {isPremintDrop
                                        ? 'Preminted Drop'
                                        : drop['pfpAttributes']
                                        ? t('drops.generative_drop')
                                        : t('drops.standard_drop')}{' '}
                                    by{' '}
                                    <Link
                                        href={`/collection/${author}`}
                                        className="mt-2"
                                    >
                                        <div className="flex flex-wrap group">
                                            <h2 className="my-auto text-primary group-hover:underline">
                                                {drop.collectionName}
                                            </h2>
                                            {drop.verified ? (
                                                <img
                                                    src={'/verified.svg'}
                                                    alt="verified"
                                                    width={24}
                                                    height={24}
                                                    className={cn(
                                                        'h-5 md:h-10 w-5 md:w-10 my-auto ml-3',
                                                    )}
                                                />
                                            ) : null}
                                        </div>
                                    </Link>
                                </div>
                            </DropPaper>
                            <DropPaper
                                noMargin
                                className="h-full mb-5 md:my-auto"
                            >
                                <DropPrice
                                    free={free}
                                    drop={drop}
                                    dropData={dropData}
                                    dropPrices={dropPrices}
                                    dropId={dropId}
                                    waxPrice={waxPrice}
                                    isPremintDrop={isPremintDrop}
                                    isPremintPoolReady={isPremintPoolReady}
                                    contract={contract}
                                    resetView={resetView}
                                    setResetView={setResetView}
                                    assetsInPool={assetsInPool}
                                    dropLink={dropLink}
                                />
                            </DropPaper>
                        </div>
                    ) : null}
                </div>
            </div>
            <div>
                {dropData?.description ? (
                    <DropPaper noMargin className="mb-5 md:mb-10">
                        <MarkDown
                            text={dropData.description}
                            className={'mx-auto'}
                        />
                    </DropPaper>
                ) : null}
                {dropData && dropData.attributes ? (
                    <>
                        <RandomPfpPreview
                            attributes={dropData.attributes}
                            authorized={authorized}
                            drop={drop}
                        />
                        <DropPfpAttributesList
                            attributes={dropData.attributes}
                            setSelectedPfpAttributeIndex={
                                setSelectedPfpAttributeIndex
                            }
                        />
                    </>
                ) : null}
                {dropData && assetsInPool && assetsInPool.length > 0 && (
                    <DropPaper
                        noMargin
                        className="mb-5 md:mb-10"
                        expandable
                        title={t('drops.mints_still_in_pool')}
                        defaultExpanded={false}
                    >
                        <div className="flex flex-wrap justify-center">
                            {assetsInPool.map((asset) => (
                                <a
                                    className="m-2"
                                    href={`/asset/${asset}`}
                                    target="_blank"
                                    key={'asset_' + asset}
                                >
                                    <Chip
                                        variant="filled"
                                        label={
                                            assetIdsToMints.has(asset)
                                                ? assetIdsToMints.get(asset)
                                                : `id: ${asset}`
                                        }
                                        color={'primary'}
                                        clickable={true}
                                        key={'asset_chip_' + asset}
                                    />
                                </a>
                            ))}
                        </div>
                    </DropPaper>
                )}
                {dropData && releaseId && dropId !== 4526 ? (
                    <DropPaper
                        noMargin
                        className="mb-5 md:mb-10"
                        expandable
                        title={t('drops.remaining_pack_content')}
                        defaultExpanded={false}
                    >
                        <div className="flex flex-wrap justify-center">
                            <PackContent releaseId={releaseId} />
                        </div>
                    </DropPaper>
                ) : (
                    ''
                )}
                {authorized &&
                    authorized.includes(userName) &&
                    dropPurchases && (
                        <DropPaper
                            title="Show list of buyers (only visible to you)"
                            expandable
                        >
                            <div className="w-auto m-8 overflow-x-auto InsideScroll">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Claimer</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Country</TableCell>
                                            <TableCell>Referrer</TableCell>
                                            <TableCell>Transaction</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dropPurchases.map(
                                            (purchase, index) => (
                                                <TableRow
                                                    key={'purchase_' + index}
                                                >
                                                    <TableCell>
                                                        {purchase['date']}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link
                                                            href={
                                                                '/user/' +
                                                                purchase[
                                                                    'claimer'
                                                                ]
                                                            }
                                                        >
                                                            {
                                                                purchase[
                                                                    'claimer'
                                                                ]
                                                            }
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {purchase['amount']}
                                                    </TableCell>
                                                    <TableCell>
                                                        {free
                                                            ? 'Free'
                                                            : formatNumber(
                                                                  purchase[
                                                                      'price'
                                                                  ],
                                                              ) +
                                                              ' ' +
                                                              purchase[
                                                                  'currency'
                                                              ]}
                                                    </TableCell>
                                                    <TableCell>
                                                        {purchase['country']}
                                                    </TableCell>
                                                    <TableCell>
                                                        {purchase['referrer']}
                                                    </TableCell>
                                                    <TableCell>
                                                        <a
                                                            href={
                                                                'https://waxblock.io/transaction/' +
                                                                purchase[
                                                                    'transactionId'
                                                                ]
                                                            }
                                                            target={'_blank'}
                                                        >
                                                            {
                                                                purchase[
                                                                    'transactionId'
                                                                ]
                                                            }
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </DropPaper>
                    )}
                {dropData && (drop['pfpAttributes'] || dropId === 4526) ? (
                    <DropPaper
                        noMargin
                        className="mb-5 md:mb-10"
                        expandable
                        title="Ranking"
                        defaultExpanded={true}
                    >
                        <div
                            className={
                                'relative flex p-2 md:p-4 3xl:p-8 m-auto'
                            }
                        >
                            <SearchComponent
                                searchSettings={{
                                    search_type: {
                                        value: 'pfps',
                                        fixed: false,
                                    },
                                    order_by: {
                                        value: 'rarity_score_desc',
                                        fixed: false,
                                    },
                                    minimal: false,
                                    tab: 'pfp',
                                    limit: {
                                        value: 60,
                                        fixed: true,
                                    },
                                    displayCollectionSelector: false,
                                    collection: {
                                        value: drop['author'],
                                        fixed: true,
                                    },
                                    schema: {
                                        value:
                                            dropId === 4526
                                                ? 'cultsuccubus'
                                                : drop['templatesToMint'][0][
                                                      'schema'
                                                  ],
                                        fixed: true,
                                        options: [],
                                    },
                                    expanded: false,
                                }}
                                limit={60}
                                userName={userName}
                                hideFilters={true}
                                tab={'pfp'}
                                listType={'pfpranking'}
                            />
                        </div>
                    </DropPaper>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}
