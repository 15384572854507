import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {
    Dialog,
    IconButton,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { DropPaper } from './DropPaper'
export const DropPfpAttributeDetailDialog = ({
    attributes,
    index,
    setIndex,
    collectionName,
    schema,
}) => {
    const [attribute, setAttribute] = useState(null)
    const [totalOdds, setTotalOdds] = useState(null)

    const HOVER_PREVIEW_HEIGHT = 'h-64'
    const HOVER_PREVIEW_WIDTH = 'w-64'

    const calcTotalOdds = (curAttribute) => {
        if (curAttribute === null) {
            return 0
        }
        const total = curAttribute.possible_values.reduce((acc, val) => {
            return acc + val.chance
        }, 0)
        return setTotalOdds(total)
    }

    const displayLayers = (layers) => {
        return (
            <div
                className={cn(
                    'relative',
                    HOVER_PREVIEW_HEIGHT,
                    HOVER_PREVIEW_WIDTH,
                    'bg-black rounded-md',
                )}
            >
                <div className="flex justify-center">
                    {layers
                        .sort((a, b) => {
                            return a.layer - b.layer
                        })
                        .map((layer) => (
                            <img
                                className={cn('absolute', HOVER_PREVIEW_HEIGHT)}
                                src={config.ipfs + layer.ipfs}
                            />
                        ))}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            attributes !== null &&
            index !== null
        ) {
            setAttribute(attributes[index])
            calcTotalOdds(attributes[index])
        } else {
            setAttribute(null)
            setTotalOdds(0)
        }
    }, [attributes, index])

    const close = () => {
        setIndex(null)
    }

    return (
        <Dialog
            open={attribute !== null}
            onClose={() => close()}
            maxWidth="sm"
            fullWidth
        >
            <DropPaper noMargin title="Attribute Details">
                <div className="mt-5">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <b>Name</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>Chance</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>Minted</b>
                                </TableCell>
                                <TableCell align="center">
                                    Search Market
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {attribute !== null &&
                            attribute.possible_values
                                .sort((a, b) => {
                                    return a.chance - b.chance
                                })
                                .map((value) => (
                                    <TableRow>
                                        <Tooltip
                                            placement="left"
                                            disableTouchListener
                                            enterDelay={300}
                                            enterNextDelay={100}
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        backgroundColor:
                                                            '#00000000',
                                                    },
                                                },
                                            }}
                                            title={displayLayers(value.layers)}
                                        >
                                            <TableCell align="center">
                                                <Link
                                                    external
                                                    href={
                                                        `/collection/${collectionName}?tab=assets&order_by=` +
                                                        `asset_id_asc&schema=${schema}&attributes=${attribute.attribute_name}:${value.value}`
                                                    }
                                                >
                                                    <span className="hover:underline">
                                                        {value.value}
                                                    </span>
                                                </Link>
                                            </TableCell>
                                        </Tooltip>
                                        <TableCell align="center">
                                            {totalOdds
                                                ? parseFloat(
                                                      100 *
                                                          (value.chance /
                                                              totalOdds),
                                                  ).toFixed(2) + '%'
                                                : 'n/a'}
                                        </TableCell>
                                        <TableCell align="center">
                                            {value.num_minted}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link
                                                external
                                                href={
                                                    `/market?collection=${collectionName}&schema=${schema}&order_by=` +
                                                    `offer_asc&attributes=${attribute.attribute_name}:${value.value}`
                                                }
                                            >
                                                <div>
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                </div>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </Table>
                </div>
            </DropPaper>
        </Dialog>
    )
}
