import { DropPaper } from './DropPaper'

export const DropPfpAttributesList = ({
    attributes,
    setSelectedPfpAttributeIndex,
}) => {
    return (
        <DropPaper
            noMargin
            className="mb-5 md:mb-10"
            expandable
            title="Attributes"
        >
            <div className="flex flex-wrap mb-5 md:mb-10">
                {attributes.map((attribute, index) => (
                    <div
                        className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
                        onClick={() => setSelectedPfpAttributeIndex(index)}
                    >
                        <div className="border border-primary bg-primary dark:!bg-paget2 text-black dark:text-primary rounded-lg py-2 cursor-pointer hover:font-bold">
                            <div className="text-center">
                                {attributes[index].attribute_name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </DropPaper>
    )
}
