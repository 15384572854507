import ShuffleIcon from '@mui/icons-material/Shuffle'
import {
    Button,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
} from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'

import { getBoostAction } from '../helpers/WaxApi'
import { DropAlert } from './DropAlert'
import { DropPaper } from './DropPaper'
import { useSharedState } from '../waxplorer/Waxplorer'

export const RandomPfpPreview = ({ attributes, authorized, drop }) => {
    const MAX_IMAGE_HEIGHT = 'max-h-80 sm:max-h-96'
    const MAX_IMAGE_WIDTH = 'max-w-xs sm:max-w-sm'
    const IMAGE_HEIGHT = 'h-80 sm:h-96'
    const IMAGE_WIDTH = 'w-80 sm:w-96'

    const [layers, setLayers] = useState([])
    const [selectedAttributes, setSelectedAttributes] = useState([])

    const [transactionId, setTransactionId] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [state] = useSharedState()

    const activeUser = state?.activeUser

    const userName = activeUser?.accountName
        ? state?.activeUser.accountName
        : null

    const { t } = useTranslation('common')

    const addOutcome = async () => {
        setTransactionId(null)
        setError(null)
        setIsLoading(true)
        const actions = [
            getBoostAction(activeUser),
            {
                account: 'nfthivedrops',
                name: 'addmanualres',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser.requestPermission
                            ? activeUser.requestPermission
                            : 'active',
                    },
                ],
                data: {
                    drop_id: drop.dropId,
                    authorized_account: userName,
                    results: selectedAttributes.map((e, index) => {
                        return {
                            attribute_name: attributes[index].attribute_name,
                            value: e.value,
                        }
                    }),
                },
            },
        ]

        try {
            const result = await activeUser.session.transact(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            if (result?.response?.transaction_id) {
                setTransactionId(result?.response?.transaction_id)
            } else {
                setError(result.status)
            }
        } catch (e) {
            setError(e.message)
        }

        setIsLoading(false)
    }

    const shuffleLayers = () => {
        setLayers([])
        const tempLayers = []
        const tempSelectedAttributes = []
        for (const attribute of attributes) {
            const randomValue =
                attribute.possible_values[
                    Math.floor(Math.random() * attribute.possible_values.length)
                ]

            tempSelectedAttributes.push(randomValue)

            for (const layer of randomValue.layers) {
                tempLayers.push({
                    ...layer,
                    value: randomValue,
                    attributeName: attribute.attribute_name,
                })
            }
        }
        tempLayers.sort((a, b) => {
            return a.layer - b.layer
        })
        setLayers(tempLayers)
        setSelectedAttributes(tempSelectedAttributes)
    }

    useEffect(() => {
        shuffleLayers()
    }, [])

    const RandomImage = () => {
        return (
            <>
                {layers.map((layer) =>
                    layer.ipfs !== '' ? (
                        <img
                            className={cn(
                                MAX_IMAGE_HEIGHT,
                                MAX_IMAGE_WIDTH,
                                'absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2',
                            )}
                            src={config.ipfs + layer.ipfs}
                        />
                    ) : null,
                )}
            </>
        )
    }

    return (
        <DropPaper
            noMargin
            className="mb-5 md:mb-10"
            expandable
            title={t('drops.possible_random_outcomes')}
        >
            <div className="flex flex-col lg:flex-row flex-wrap justify-center">
                <div className="italic text-center text-sm mb-10">
                    <DropAlert severity="warning" condition={true}>
                        {t('drops.random_outcomes_explanation')}
                    </DropAlert>
                </div>
                <div className="w-full lg:w-auto flex justify-center">
                    <div
                        className={cn(
                            IMAGE_HEIGHT,
                            IMAGE_WIDTH,
                            'my-auto relative',
                        )}
                    >
                        <div className="flex justify-center">
                            <RandomImage />
                        </div>
                    </div>
                </div>
                <div className="my-auto mx-0 lg:mx-5 w-full lg:w-auto flex justify-center">
                    <IconButton
                        color="primary"
                        onClick={() => {
                            shuffleLayers()
                        }}
                    >
                        <ShuffleIcon />
                    </IconButton>
                </div>
                <div className="w-full lg:w-auto flex justify-center">
                    <div
                        className={cn(
                            'my-auto text-center lg:text-left',
                            IMAGE_WIDTH,
                        )}
                    >
                        {selectedAttributes.length > 0
                            ? attributes.map((attribute, index) => (
                                  <div
                                      key={'attr_select_' + index}
                                      className="mb-3"
                                  >
                                      <FormControl
                                          fullWidth={true}
                                          size="small"
                                      >
                                          <InputLabel>
                                              {attribute.attribute_name}
                                          </InputLabel>
                                          <Select
                                              label={attribute.attribute_name}
                                              value={selectedAttributes[index]}
                                              fullWidth={true}
                                              size="small"
                                              onChange={(e) => {
                                                  // Remove all layers of this attribute (for multi layer attributes)
                                                  const tempLayers = [
                                                      ...layers,
                                                  ].filter((element) => {
                                                      return (
                                                          element.attributeName !==
                                                          attribute.attribute_name
                                                      )
                                                  })

                                                  const tempSelectedAttributes =
                                                      [...selectedAttributes]

                                                  // Add all layers of the selected attribute
                                                  const newValue =
                                                      e.target.value

                                                  tempSelectedAttributes[
                                                      index
                                                  ] = newValue

                                                  for (const newValueLayer of newValue.layers) {
                                                      tempLayers.push({
                                                          ...newValueLayer,
                                                          value: newValue,
                                                          attributeName:
                                                              attribute.attribute_name,
                                                      })
                                                  }
                                                  // Sort all layers and update
                                                  tempLayers.sort((a, b) => {
                                                      return a.layer - b.layer
                                                  })
                                                  setLayers(tempLayers)
                                                  setSelectedAttributes(
                                                      tempSelectedAttributes,
                                                  )
                                              }}
                                          >
                                              {attributes[
                                                  index
                                              ].possible_values.map(
                                                  (possibleValue) => (
                                                      <MenuItem
                                                          value={possibleValue}
                                                      >
                                                          {possibleValue.value}
                                                      </MenuItem>
                                                  ),
                                              )}
                                          </Select>
                                      </FormControl>
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
            </div>
            {userName && authorized && authorized.includes(userName) ? (
                <>
                    <div className={'mt-8 mb-2 w-full flex justify-center'}>
                        <Button
                            variant="outlined"
                            onClick={addOutcome}
                            disabled={isLoading}
                        >
                            {t('buttons.add_outcome')}
                        </Button>
                    </div>
                    <div className="italic text-center text-sm mt-2">
                        {t('drops.add_outcome_explanation')}
                    </div>

                    <DropAlert
                        condition={error}
                        severity="error"
                        className="mb-5"
                    >
                        {error}
                    </DropAlert>
                    <DropAlert
                        condition={transactionId}
                        transactionId={transactionId}
                        title={t('alerts.success')}
                        className="mb-5"
                    />
                    <Collapse in={isLoading}>
                        <div className="mb-10">
                            <LinearProgress />
                        </div>
                    </Collapse>
                </>
            ) : (
                ''
            )}
        </DropPaper>
    )
}
