import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import { get } from '../helpers/Api'
import { formatUSD, formatWAX } from '../helpers/FormatLinks'
import { DropAlert } from './DropAlert'
import { DropButton } from './DropButton'
import { DropButtons } from './DropButtons'
import { useCurrencies, useTokens } from '../../hooks'
import moment from 'moment'
export const DropPrice = ({
    free,
    drop,
    dropData,
    dropPrices,
    dropId,
    waxPrice,
    isPremintDrop,
    isPremintPoolReady,
    contract,
    resetView,
    setResetView,
    assetsInPool,
    dropLink,
}) => {
    const { t } = useTranslation('common')

    const [tokenPrices, setTokenPrices] = useState({})

    const { currencies } = useCurrencies()

    const [tokens, setTokens] = useState([])
    const [dropTimeLeft, setDropTimeLeft] = useState('')
    const [dropInterval, setDropInterval] = useState(null)

    const parseDropTokenPrices = (res, amount) => {
        if (res) {
            tokenPrices[res['symbol']] = res
            tokenPrices[res['symbol']]['amount'] = amount
            setTokenPrices(tokenPrices)
        }
    }

    const getTokens = () => {
        get('get-wax-tokens', 'api1').then(setTokens)
    }

    useEffect(() => {
        if (dropData && dropData.endTime) {
            const currentTime = moment()

            const diffTime = dropData.endTime - currentTime.unix()

            if (diffTime > 0) {
                if (dropInterval) {
                    clearInterval(dropInterval)
                }

                const interval = 1000

                setDropInterval(
                    setInterval(function () {
                        const currentTime = moment()

                        const diffTime = dropData.endTime - currentTime.unix()

                        let duration = moment.duration(
                            diffTime * 1000,
                            'milliseconds',
                        )

                        duration = moment.duration(
                            duration - interval,
                            'milliseconds',
                        )

                        if (dropInterval) {
                            clearInterval(dropInterval)
                        }

                        if (duration.asSeconds() < 0) setDropTimeLeft(null)
                        else
                            setDropTimeLeft(
                                `${duration.months()}M ${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`,
                            )
                    }, interval),
                )
            }
        }
    }, [dropData && dropData.endTime])

    const formatDropPrice = (price) => {
        return (
            <Link
                href={`https://wax.alcor.exchange/trade/${price.symbol}-${price.contract}_wax-eosio.token`}
                className={'flex'}
                external={true}
            >
                <div
                    className={
                        'ml-2 font-normal text-sm opacity-80 hover:opacity-100'
                    }
                >
                    (~ {formatWAX(price.waxPrice * price.amount)} /{' '}
                    {formatUSD(price.usdPrice * price.amount)})
                </div>
                <img
                    className={'ml-2 h-5 flex justify-center'}
                    src={'/alcor.svg'}
                />
            </Link>
        )
    }

    const formatTokenImage = (price) => {
        const filtered = tokens.filter(
            (token) =>
                price &&
                price.symbol &&
                price.contract &&
                token.symbol === price.symbol.split(',')[1] &&
                token.account === price.contract,
        )

        if (filtered.length > 0 && filtered[0]['logo']) {
            return (
                <Link
                    href={`https://waxblock.io/account/${filtered[0]['account']}`}
                    external={true}
                    className={'w-8 h-8 flex my-auto'}
                    margin={'mr-2'}
                >
                    <img className={'h-full'} src={filtered[0]['logo']} />
                </Link>
            )
        }

        return ''
    }

    useEffect(() => {
        if (!free) {
            if (
                !dropPrices &&
                dropData &&
                dropData.listingPrice &&
                dropData.listingPrice.split(' ').length === 2
            ) {
                const token = dropData.listingPrice.split(' ')[1]
                const amount = parseFloat(dropData.listingPrice.split(' ')[0])
                if (!['WAX', 'USD', 'NULL'].includes(token)) {
                    get(`drop-token-price/${token}`, 'v3').then((res) =>
                        parseDropTokenPrices(res, amount),
                    )
                }
            } else if (dropPrices) {
                for (const dropPrice of dropPrices) {
                    if (dropPrice.split(' ').length === 2) {
                        const token = dropPrice.split(' ')[1]
                        const amount = dropPrice.split(' ')[0]
                        if (!['WAX', 'USD', 'NULL'].includes(token)) {
                            get(`drop-token-price/${token}`, 'v3').then((res) =>
                                parseDropTokenPrices(res, amount),
                            )
                        }
                    }
                }
            }
        }
        if (tokens.length === 0) {
            getTokens()
        }
    }, [dropData, dropPrices && dropPrices.length])

    return (
        <div>
            {dropPrices ? (
                // Multiple Prices
                <div>
                    <div className="font-semibold mb-2">
                        {t('drops.purchase_with_currencies')}
                    </div>
                    <div>
                        {dropPrices.map((price) => (
                            <div
                                className={
                                    'font-bold md:font-semibold text-xl md:text-3xl mb-2'
                                }
                            >
                                <div className={'flex justify-start'}>
                                    {' '}
                                    {currencies &&
                                    price &&
                                    price.split(' ').length === 2 &&
                                    currencies.filter(
                                        (curr) =>
                                            curr.symbol.split(',')[1] ===
                                            price.split(' ')[1],
                                    ).length > 0
                                        ? formatTokenImage(
                                              currencies.filter(
                                                  (curr) =>
                                                      curr.symbol.split(
                                                          ',',
                                                      )[1] ===
                                                      price.split(' ')[1],
                                              )[0],
                                          )
                                        : ''}
                                    <div>{price}</div>
                                </div>
                                {tokenPrices &&
                                price &&
                                price.split(' ').length === 2 &&
                                Object.keys(tokenPrices).includes(
                                    price.split(' ')[1],
                                )
                                    ? formatDropPrice(
                                          tokenPrices[price.split(' ')[1]],
                                      )
                                    : ''}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                // One Price
                <div className="font-bold md:font-semibold text-xl md:text-4xl mb-5">
                    <div className={'flex justify-start'}>
                        {currencies &&
                        dropData &&
                        dropData.listingPrice &&
                        dropData.listingPrice.split(' ').length === 2 &&
                        currencies.filter(
                            (curr) =>
                                curr.symbol.split(',')[1] ===
                                dropData.listingPrice.split(' ')[1],
                        ).length > 0
                            ? formatTokenImage(
                                  currencies.filter(
                                      (curr) =>
                                          curr.symbol.split(',')[1] ===
                                          dropData.listingPrice.split(' ')[1],
                                  )[0],
                              )
                            : ''}
                        <div>
                            {free ? 'Free' : dropData && dropData.listingPrice}
                        </div>
                    </div>
                    {tokenPrices &&
                    dropData &&
                    dropData.listingPrice &&
                    dropData.listingPrice.split(' ').length === 2 &&
                    Object.keys(tokenPrices).includes(
                        dropData.listingPrice.split(' ')[1],
                    ) ? (
                        <div>
                            {formatDropPrice(
                                tokenPrices[
                                    dropData.listingPrice.split(' ')[1]
                                ],
                            )}
                        </div>
                    ) : null}
                </div>
            )}
            {/* If USD */}
            {waxPrice ? (
                <div className={cn('font-bold md:font-semibold text-sm')}>
                    ~ {waxPrice}
                </div>
            ) : null}

            <DropAlert condition={dropData?.authRequired} severity="warning">
                Authorization Required
            </DropAlert>
            <DropAlert
                condition={
                    drop.startTime &&
                    new Date(drop.startTime * 1000) > new Date()
                }
                title={`Why can't I buy this drop?`}
                severity="info"
            >
                This drop is scheduled for later.
            </DropAlert>
            <DropAlert
                condition={
                    dropData &&
                    isPremintDrop &&
                    !isPremintPoolReady &&
                    dropData.currentClaimed === 0
                }
                title={`Why can't I buy this drop?`}
                severity="info"
            >
                This is a premint drop. The creator will mint all NFTs for this
                drop themselves, and then add them here. Once they've done that,
                you will be able to buy it.
            </DropAlert>
            <div className="mb-5">
                {contract === 'atomicdropsx' && (
                    <DropButton
                        size="large"
                        href={'https://wax.atomichub.io/drops/' + dropId}
                        external
                    >
                        Get it on Atomichub
                    </DropButton>
                )}
                {contract === 'neftyblocksd' && (
                    <DropButton
                        size="large"
                        href={`https://${
                            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                                ? 'test.'
                                : ''
                        }neftyblocks.com/c/${
                            drop.author
                        }/drops/${dropId}?ref=nft.hive`}
                        external
                    >
                        Get it on Neftyblocks
                    </DropButton>
                )}
                {contract === 'nfthivedrops' && dropData && (
                    <DropButtons
                        drop={drop}
                        dropData={dropData}
                        resetView={resetView}
                        setResetView={setResetView}
                        assetsInPool={assetsInPool}
                        isPremintDrop={isPremintDrop}
                        isPremintPoolReady={isPremintPoolReady}
                        dropLink={dropLink}
                    />
                )}
            </div>
            {dropData ? (
                <div className="mt-10 font-semibold">
                    Total Claimed: {dropData.currentClaimed}
                    {dropData.maxClaimable > 0 && ` / ${dropData.maxClaimable}`}
                </div>
            ) : null}
            {dropTimeLeft ? (
                <div>
                    <span className="font-semibold">
                        {t('drops.time_remaining')}:{' '}
                    </span>
                    <span className="font-bold">{dropTimeLeft}</span>
                </div>
            ) : null}
        </div>
    )
}
