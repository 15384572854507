import cn from 'classnames'
import ColorThief from 'colorthief'
import React, { useContext, useEffect, useState } from 'react'
import config from '../../config.json'
import Icon from '../common/util/input/Icon'
import { getImageData, getViewer, rgbToHex } from '../helpers/FormatLinks'
import { Context } from '../waxplorer/Waxplorer'
import { getAtomicTemplate } from '../helpers/Api'
export const DropItemImage = ({ drop, dropData, item }) => {
    const [imagePosition, setImagePosition] = useState(0)
    const [modelViewer, setModelViewer] = useState(null)
    const [videoPlayer, setVideoPlayer] = useState(null)
    const IMG_CLASS = 'm-auto max-h-64 md:max-h-[70vh] md:py-[2.5vh] max-w-full'

    const [atomicData, setAtomicData] = useState()

    const { idata, templateId } = item
        ? item
        : {
              templateId: null,
              idata: null,
          }

    const assetData = idata ? JSON.parse(idata) : null

    let image = ''
    let backimg = ''
    let video = ''
    let model = ''
    if (assetData && Object.keys(assetData).includes('img')) {
        image = assetData['img'].includes('http')
            ? assetData['img']
            : `${config.ipfs}${assetData['img']}`
    }

    const displayData = drop['displayData']

    if (displayData && displayData.includes('preview_img')) {
        image = JSON.parse(displayData)['preview_img'].includes('http')
            ? JSON.parse(displayData)['preview_img']
            : config.ipfs + JSON.parse(displayData)['preview_img']
    }

    for (let backImgName of [
        'backimg',
        'BackingIMG',
        'backimage',
        'imgback',
        'Back',
        'img_back',
        'Back Image',
        'img back',
        'Cover',
    ]) {
        if (
            assetData &&
            Object.keys(assetData).includes(backImgName) &&
            !backimg
        ) {
            backimg = assetData[backImgName].includes('http')
                ? assetData[backImgName]
                : `${config.ipfs}${assetData[backImgName]}`
        }
    }
    if (
        assetData &&
        Object.keys(assetData).includes('video') &&
        assetData['video']
    ) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    } else if (assetData && Object.keys(assetData).includes('media')) {
        video = assetData['media'].includes('http')
            ? assetData['media']
            : `${config.ipfs}${assetData['media']}`
    } else if (assetData && Object.keys(assetData).includes('3dimg')) {
        model = assetData['3dimg'].includes('http')
            ? assetData['3dimg']
            : `${config.ipfs}${assetData['3dimg']}`
    } else if (assetData && Object.keys(assetData).includes('3d')) {
        model = assetData['3d'].includes('http')
            ? assetData['3d']
            : `${config.ipfs}${assetData['3d']}`
    } else if (assetData && Object.keys(assetData).includes('model')) {
        model = assetData['model'].includes('http')
            ? assetData['model']
            : `${config.ipfs}${assetData['model']}`
    }

    if (assetData && Object.keys(assetData).includes('3dimg')) {
        model = assetData['3dimg'].includes('http')
            ? assetData['3dimg']
            : `${config.ipfs}${assetData['3dimg']}`
    }

    let format = null

    const images = []
    const imageFormats = []

    if (atomicData && atomicData['schema']) {
        format = atomicData['schema']['format']
        const formatDict = {}
        for (let a of format) {
            formatDict[a['name']] = a['type']
        }
        for (const key of Object.keys(formatDict)) {
            if (assetData && Object.keys(assetData).includes(key)) {
                if (formatDict[key] === 'audio' || key === 'audio') {
                    images.push(
                        assetData['audio'].includes('http')
                            ? assetData['audio']
                            : `${config.ipfs}${assetData['audio']}`,
                    )
                    imageFormats.push('audio')
                } else if (formatDict[key] === 'video' || key === 'video') {
                    const video = assetData[key].includes('http')
                        ? assetData[key]
                        : `${config.ipfs}${assetData[key]}`

                    if (video) {
                        images.push(
                            video.replace(
                                'https://gateway.pinata.cloud/ipfs/',
                                'https://ipfs.hivebp.io/ipfs/',
                            ),
                        )
                        imageFormats.push('video')
                    }
                } else if (formatDict[key] === 'Video' || key === 'Video') {
                    const video = assetData[key].includes('http')
                        ? assetData[key]
                        : `${config.ipfs}${assetData[key]}`

                    if (video) {
                        images.push(
                            video.replace(
                                'https://gateway.pinata.cloud/ipfs/',
                                'https://ipfs.hivebp.io/ipfs/',
                            ),
                        )
                        imageFormats.push('video')
                    }
                } else if (['model', '3dimg', '3d'].includes(key)) {
                    const model = assetData[key].includes('http')
                        ? assetData['3dimg']
                        : `${config.ipfs}${assetData['3dimg']}`
                    images.push(model)
                    imageFormats.push('model')
                } else if (
                    (formatDict[key] === 'image' ||
                        formatDict[key] === 'ipfs' ||
                        key === 'img') &&
                    !assetData[key].includes('video:')
                ) {
                    const image = assetData[key].includes('http')
                        ? assetData[key]
                        : `${config.ipfs}${assetData[key]}`
                    if (image.includes('video:')) {
                        images.push(
                            image
                                .replace(
                                    'https://gateway.pinata.cloud/ipfs/',
                                    'https://ipfs.hivebp.io/ipfs/',
                                )
                                .replace('video:', ''),
                        )
                        imageFormats.push('video')
                    } else {
                        images.push(
                            image.replace(
                                'https://gateway.pinata.cloud/ipfs/',
                                'https://ipfs.hivebp.io/ipfs/',
                            ),
                        )
                        imageFormats.push('image')
                    }
                }
            }
        }
    } else {
        let video = ''
        let audio = ''
        let model = ''
        if (
            assetData &&
            Object.keys(assetData).includes('video') &&
            assetData['video']
        ) {
            video = assetData['video'].includes('http')
                ? assetData['video']
                : `${config.ipfs}${assetData['video']}`
        } else if (
            assetData &&
            Object.keys(assetData).includes('Video') &&
            assetData['Video']
        ) {
            video = assetData['Video'].includes('http')
                ? assetData['Video']
                : `${config.ipfs}${assetData['Video']}`
        } else if (
            assetData &&
            Object.keys(assetData).includes('media') &&
            assetData['media']
        ) {
            video = assetData['media'].includes('http')
                ? assetData['media']
                : `${config.ipfs}${assetData['media']}`
        } else if (
            assetData &&
            Object.keys(assetData).includes('3dimg') &&
            assetData['3dimg']
        ) {
            model = assetData['3dimg'].includes('http')
                ? assetData['3dimg']
                : `${config.ipfs}${assetData['3dimg']}`
        } else if (
            assetData &&
            Object.keys(assetData).includes('3d') &&
            assetData['3d']
        ) {
            model = assetData['3d'].includes('http')
                ? assetData['3d']
                : `${config.ipfs}${assetData['3d']}`
        } else if (
            assetData &&
            Object.keys(assetData).includes('model') &&
            assetData['model']
        ) {
            model = assetData['model'].includes('http')
                ? assetData['model']
                : `${config.ipfs}${assetData['model']}`
        }

        if (assetData && Object.keys(assetData).includes('3dimg')) {
            model = assetData['3dimg'].includes('http')
                ? assetData['3dimg']
                : `${config.ipfs}${assetData['3dimg']}`
        }

        if (assetData && Object.keys(assetData).includes('audio')) {
            audio = assetData['audio'].includes('http')
                ? assetData['audio']
                : `${config.ipfs}${assetData['audio']}`
        }

        if (video) {
            images.push(
                video.replace(
                    'https://gateway.pinata.cloud/ipfs/',
                    'https://ipfs.hivebp.io/ipfs/',
                ),
            )
            imageFormats.push('video')
        }

        if (image && !image.includes('video:')) {
            images.push(
                image.replace(
                    'https://gateway.pinata.cloud/ipfs/',
                    'https://ipfs.hivebp.io/ipfs/',
                ),
            )
            imageFormats.push('image')
        }

        if (model) {
            images.push(model)
            imageFormats.push('model')
        }

        if (audio) {
            images.push(audio)
            imageFormats.push('video')
        }

        if (backimg) {
            images.push(backimg)
            imageFormats.push('image')
        }
    }

    getImageData(images, imageFormats, assetData, format)

    if (images.length === 0 && atomicData && atomicData['immutable_data']) {
        getImageData(images, imageFormats, atomicData['immutable_data'], format)
    }

    if (images.length === 0 && image) {
        images.push(image)
        imageFormats.push('image')
    }

    useEffect(() => {
        if (imagePosition >= images.length) {
            setImagePosition(0)
        }
        if (!modelViewer && model && image) {
            getViewer(images[imagePosition], image).then(setModelViewer)
        }
        if (imageFormats[imagePosition] === 'video') {
            setVideoPlayer(
                <video
                    loop
                    playsInline={true}
                    autoPlay={true}
                    muted={true}
                    poster={image ? image : backimg}
                    className={IMG_CLASS}
                    onClick={() =>
                        toggleImage('video:' + images[imagePosition])
                    }
                >
                    <source src={images[imagePosition]} />
                    Your browser does not support the video tag.
                </video>,
            )
        }
    }, [templateId, imagePosition, image])

    const [bgColor, setBgColor] = useState('#000000')

    const parseTemplate = (res) => {
        if (res && res.success) setAtomicData(res.data)
    }

    useEffect(() => {
        getAtomicTemplate(templateId, item.author).then(parseTemplate)
    }, [templateId])

    useEffect(() => {
        if (images[imagePosition]) {
            const colorThief = new ColorThief()
            const img = new Image()
            img.addEventListener('load', () => {
                try {
                    const color = colorThief.getColor(img, 30)
                    const rgb = rgbToHex(color[0], color[1], color[2])
                    setBgColor(rgb)
                } catch (e) {
                    console.error(e)
                }
            })
            let imageURL = images[imagePosition]
            let googleProxyURL =
                'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url='
            img.crossOrigin = 'Anonymous'
            img.src = googleProxyURL + encodeURIComponent(imageURL)
        }
    }, [images])

    const [, dispatch] = useContext(Context)

    const toggleImage = (img) => {
        dispatch({ type: 'FULL_IMAGE', payload: img })
    }

    return (
        <div className="relative group">
            {dropData.maxClaimable > 0 &&
                dropData.currentClaimed >= dropData.maxClaimable && (
                    <div
                        className={cn(
                            'text-red-600 text-3xl font-extrabold',
                            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-12',
                            'border-red-600 p-2 rounded-md border-4 bg-red-100/90 z-100 text-center',
                            'group-hover:invisible grayscale-0',
                        )}
                    >
                        SOLD OUT
                    </div>
                )}
            <div
                className={cn(
                    'hover:grayscale-0 flex flex-wrap rounded-b-md',
                    'w-full h-full ',
                    dropData.maxClaimable > 0 &&
                        dropData.currentClaimed >= dropData.maxClaimable
                        ? 'grayscale'
                        : '',
                )}
                style={{ background: bgColor }}
            >
                {imageFormats[imagePosition] === 'video' && videoPlayer ? (
                    videoPlayer
                ) : imageFormats[imagePosition] === 'model' &&
                  typeof window !== 'undefined' &&
                  modelViewer ? (
                    modelViewer
                ) : (
                    <img
                        onClick={() => toggleImage(images[imagePosition])}
                        src={images[imagePosition]}
                        alt="none"
                        className={IMG_CLASS}
                    />
                )}
                {images.length > 1 && (
                    <div
                        className={cn(
                            'relative w-full p-4 flex justify-evenly ',
                        )}
                    >
                        {images.map((image, index) => (
                            <div
                                className={cn(
                                    'w-7 h-7 text-2xl text-white',
                                    'cursor-pointer bg-transparent border-none outline-none',
                                )}
                                onClick={() => {
                                    setImagePosition(index)
                                }}
                            >
                                <Icon>
                                    <img
                                        className={'w-5 h-5'}
                                        src={
                                            index === imagePosition
                                                ? '/radio-button-on.svg'
                                                : '/radio-button-off.svg'
                                        }
                                        alt="radio"
                                        width={150}
                                        height={150}
                                    />
                                </Icon>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
