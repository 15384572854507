import { LinearProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { AssetList } from '../assetlist/AssetList'
import { get } from '../helpers/Api'

export const PackContent = ({ releaseId }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [releaseContent, setReleaseContent] = useState(false)

    const parseReleaseContent = (res) => {
        setReleaseContent(res)
        setIsLoading(false)
    }

    useEffect(() => {
        setIsLoading(true)
        get(`release-pool-content/${releaseId}`).then(parseReleaseContent)
    }, [releaseId])

    return (
        <div>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <AssetList
                    assets={
                        releaseContent
                            ? releaseContent.sort(
                                  (a, b) => a.numRemaining - b.numRemaining,
                              )
                            : ''
                    }
                    listType={'pack-content'}
                />
            )}
        </div>
    )
}
