import { Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from '../helpers/Api'
import { DropComponent } from './DropComponent'

const DropView = (props) => {
    const { contract } = props
    const { dropIds } = props

    const dropId = dropIds[0]

    const [dropsData, setDropsData] = useState(null)
    const [isLoadingDrops, setIsLoadingDrops] = useState(false)

    const { t } = useTranslation('common')

    const parseDropsData = (res) => {
        setDropsData(res)
        setIsLoadingDrops(false)
    }

    useEffect(() => {
        setIsLoadingDrops(true)
        get(`drops-data/${contract}?drop_ids=${dropIds.join(',')}`).then(
            parseDropsData,
        )
    }, [dropId])

    return (
        <div className={'w-full px-2 md:px-8'}>
            <div className={'flex flex-wrap'}>
                {isLoadingDrops || !dropsData ? (
                    <></>
                ) : dropsData.length === 0 ? (
                    <div className="mx-auto my-10">
                        <Alert severity="error">
                            {t('drops.no_drop_with_that_id_exists')}
                        </Alert>
                    </div>
                ) : dropsData[0].erased ? (
                    <div className="mx-auto my-10">
                        <Alert severity="error">
                            {t('drops.this_drop_has_been_deleted')}
                        </Alert>
                    </div>
                ) : dropsData[0].blacklisted ? (
                    <div className="mx-auto my-10">
                        <Alert severity="error">
                            {t('drops.this_drop_has_been_deleted')}
                        </Alert>
                    </div>
                ) : (
                    dropsData.map((drop) => <DropComponent drop={drop} />)
                )}
            </div>
        </div>
    )
}

export default DropView
